(function($) {
  $.fn.tableSelect = function() {
    return this.each(function() {
      var container = $(this);

      var selection_mode = 1; // 1 - inclusion, 2 - exclusion
      var selected = [];
      function table_select_pagination_info() {
        var total = parseInt(container.find('.pagination_info_selected').data("selectable-total"));
        if(selected.length > 0 || selection_mode == 2) {
          container.find('.pagination_info_default').hide();
          container.find('.pagination_info_selected').show();
          container.find('.pagination_info_selected_count').html(selection_mode == 1 ? selected.length : total - selected.length);
        } else {
          container.find('.pagination_info_default').show();
          container.find('.pagination_info_selected').hide();
        }
      }
      function table_select_callback() {
        var checked = this.checked;
        var id = parseInt($(this).closest("tr").data("model-id"));
        if((checked && selection_mode == 1) || (!checked && selection_mode == 2)) {
          if(selected.indexOf(id) < 0) selected.push(id);
        } else {
          var index = selected.indexOf(id);
          if(index > -1) selected.splice(index, 1);
        }
        if(checked) container.find('tr[data-model-id='+id+']').addClass("success");
        else {
          container.find('tr[data-model-id='+id+']').removeClass("success");
          if(selection_mode == 1) {
            container.find('.table_all_selection_checkbox').prop('checked', false);
            container.find(".select_all_question").remove();
          }
        }
        table_select_pagination_info();
      }
      function table_select_all_callback() {
        var checked = this.checked;
        var total = parseInt(container.find('.pagination_info_selected').data("selectable-total"));
        if(checked) {
          container.find('tr[data-model-id]').each(function(index) {
            var id = parseInt($(this).data("model-id"));
            if(selected.indexOf(id) < 0) selected.push(id);
          });
          container.find('tr[data-model-id]').addClass("success").find(".table_selection_checkbox").prop('checked', true);
          if(selected.length < total) {
            var cols = 0;
            container.find('tbody tr:first td').map(function(i,o) { cols += ( $(o).attr('colspan') === undefined ? 1 : parseInt($(o).attr('colspan')) ) } );
            var row = $('<tr class="select_all_question active text-muted"><td colspan="'+cols+'" style="text-align:center; padding:0;"><div id="select_all_question_content" style="display:none; padding:8px;">You have selected all items in this page only. <a href="#">Click here to select everything.</a></div></td></tr>');
            row.find("a").click(function() { table_select_everything(); return false; });
            container.find('tbody:first').prepend(row);
            row.find("#select_all_question_content").slideDown();
          }
        } else {
          container.find(".select_all_question").remove();
          if(selection_mode == 2) {
            selection_mode = 1;
            selected = [];
          } else {
            container.find('tr[data-model-id]').each(function(index) {
              var id = parseInt($(this).data("model-id"));
              var index = selected.indexOf(id);
              if(index > -1) selected.splice(index, 1);
            });
          }
          container.find('tr[data-model-id]').removeClass("success").find(".table_selection_checkbox").prop('checked', false);
        }
        table_select_pagination_info();
      }
      function table_select_everything() {
        container.find('.table_all_selection_checkbox').prop('checked', true);
        container.find(".select_all_question").remove();
        selection_mode = 2;
        container.find('tr[data-model-id]').addClass("success").find(".table_selection_checkbox").prop('checked', true);
        selected = [];
        table_select_pagination_info();
      }
      function table_select_destroy_many_callback() {
        if(!confirm("Are you sure you want to delete all selected items?")) return false;
        var table_select = container.find("table[data-table-select]");
        var destroy_many_path = table_select.data("destroy-many");
        var return_to_path = table_select.data("return-to");
        container.find('.pagination_info').html("<i class='fa-solid fa-circle-notch fa-spin'></i>");
        $.ajax({
          type: "POST",
          url: destroy_many_path,
          data: $.param({ids: (selection_mode == 1 ? [] : ["~"]).concat(selected), return_to: return_to_path}) + (container.is("form") ? "&"+container.serialize() : ""),
          success: function(text) {//execute script here
            container.trigger("searcher:searched");
          },
          dataType: "script"
        });
        return false;
      }
      function table_select_action_callback() {
        var action_param = container.find('.pagination_info_selected').data("action-param");
        var url = this.href;
        url += url.indexOf('?') > -1 ? "&" : "?";
        var data = {};
        data[action_param] = (selection_mode == 1 ? [] : ["~"]).concat(selected);
        url += $.param(data);
        window.location.href = url;
        return false;
      }
      function table_select_init() {
        container.find("table[data-table-select] thead tr").each(function(index) {
          $(this).prepend(index == 0 ? '<th><input type="checkbox" class="table_all_selection_checkbox form-check-input" /></th>' : '<th></th>')
        });
        container.find(".table_all_selection_checkbox").change(table_select_all_callback);
        container.find(".table_selection_checkbox").change(table_select_callback);
        container.find(".pagination_info_selected_destroy_many").click(table_select_destroy_many_callback);
        container.find(".pagination_info_selected_actions a").click(table_select_action_callback);
      }
      container.on("searcher:searched", function(event) {
        table_select_init();
        selection_mode = 1;
        selected = [];
      });
      container.on("searcher:browsed", function(event) {
        table_select_init();
        if(selection_mode == 2) container.find('.table_all_selection_checkbox').prop('checked', true);
        container.find('tr[data-model-id]').each(function(index) {
          var id = parseInt($(this).data("model-id"));
          if(selection_mode == 1 && selected.indexOf(id) > -1) $(this).addClass("success").find(".table_selection_checkbox").prop('checked', true);
          if(selection_mode == 2 && selected.indexOf(id) <= -1) $(this).addClass("success").find(".table_selection_checkbox").prop('checked', true);
        });
        table_select_pagination_info();
      });
      table_select_init();

    });
  };
}(jQuery));
