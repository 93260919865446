function getPreferredTheme() {
  return localStorage.getItem('theme') || "auto";
}

function setTheme() {
  var theme = getPreferredTheme();
  if(theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) theme = "dark";
  document.documentElement.setAttribute('data-bs-theme', theme)
}

function makeThemeLinks() {
  var container = document.getElementById("theme_links");
  if(!container) return;
  var themes = JSON.parse(container.getAttribute("data-themes"));
  var theme = getPreferredTheme();
  var html = "";
  for(var i = 0; i<themes.themes.length; i++) {
    if(themes.themes[i] == theme) html += "<strong>"+themes.labels[i]+"</strong> &nbsp;";
    else html += '<a href="#" onclick="changeTheme(\''+themes.themes[i]+'\'); return false;">'+themes.labels[i]+"</a> &nbsp;";
  }
  container.innerHTML = html;
}

function changeTheme(theme) {
  localStorage.setItem('theme', theme);
  setTheme();
  makeThemeLinks();
}
global.changeTheme = changeTheme;

window.addEventListener('DOMContentLoaded', function() {
  setTheme();
  makeThemeLinks();
});

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function(e) {
  console.log("(prefers-color-scheme: dark) changed");
  setTheme();
});
