import $ from "jquery"

//logs
var log_facility_id = 0;
var log_tz_offset = 0;
var log_device_starts_online = false;

export function log_init(facility_id, tz_offset, starts_online) {
  log_facility_id = facility_id;
  log_tz_offset = tz_offset;
  log_device_starts_online = starts_online;
  $("#device_control_logs").freezeHeader({'height': '200px'});
  var expand_button = document.getElementById("device_control_logs_expand_button");
  if(expand_button) expand_button.addEventListener("click", device_control_logs_expand);
}
function device_control_logs_expand() {
  $("#hdScrolldevice_control_logs").css("height", Math.max($(window).height()-260, 200)+'px');
  $(window).resize(function() {
    $("#hdScrolldevice_control_logs").css("height", Math.max($(window).height()-260, 200)+'px');
  });
}

function log_append(html) {
  var scroll = $("#hdScrolldevice_control_logs").scrollTop()+$("#hdScrolldevice_control_logs").height() >= $("#device_control_logs").height() - 10;
  $('#device_control_logs tbody').append(html);
  if(scroll) $("#hdScrolldevice_control_logs").scrollTop($("#device_control_logs").height());
}
function log_state(log) {
  if(log.state == "connected") $("#device_control_logs_websockets_status").html('<i class="fa-solid fa-fw fa-check-circle text-success" aria-hidden="true"></i>');
  if(log.state == "disconnected") $("#device_control_logs_websockets_status").html('<i class="fa-solid fa-fw fa-exclamation-circle text-danger" aria-hidden="true"></i>');
  log_append('\
    <tr>\
      <td colspan="2">'+(log.state == "connected" ? '<i class="fa-solid fa-fw fa-check-circle text-success" aria-hidden="true"></i>' : log.state == "disconnected" ? '<i class="fa-solid fa-fw fa-exclamation-circle text-danger" aria-hidden="true"></i>' : log.state == "reconnecting" ? '<i class="fa-solid fa-fw fa-exclamation-triangle text-warning" aria-hidden="true"></i>' : '')+' '+log.message+'</td>\
    </tr>'
  );
}
function capitalize(str) { return str.charAt(0).toUpperCase() + str.slice(1); }
function log_format_time(time) {
  var t = new Date(time.getTime() + log_tz_offset*1000);
  var month = (t.getUTCMonth()+1);
  var date = t.getUTCDate();
  var hour = t.getUTCHours();
  var min = t.getUTCMinutes();
  var sec = t.getUTCSeconds();
  return t.getUTCFullYear()+'-'+(month < 10 ? "0" : "")+month+'-'+(date < 10 ? "0" : "")+date+' ' +
    (hour < 10 ? "0" : "")+hour+':'+(min < 10 ? "0" : "")+min+':'+(sec < 10 ? "0" : "")+sec;
}
function log_format_message(message, time) {
  var data = "";
  Object.keys(message.data).forEach(function (key) {
    if(data != "") data += ", ";
    data += key + ": ";
    var value = message.data[key];
    switch(key) {
      case "tv_channel_id":
        data += "<i><a href=\"/facilities/"+log_facility_id+"/tv_channels/"+value+"\">"+value+"</a></i>";
        break;
      case "movie_id":
        data += "<i><a href=\"/facilities/"+log_facility_id+"/movies/"+value+"\">"+value+"</a></i>";
        break;
      case "banner_id":
        data += "<i><a href=\"/facilities/"+log_facility_id+"/banners/"+value+"\">"+value+"</a></i>";
        break;
      default:
        value = Array.isArray(value) ? "["+value.join(", ")+"]" : ""+value;
        data += "<i>"+(value.length <= 12 ? value : "<abbr title=\""+value+"\nClick to expand!\" onclick=\"this.parentElement.innerText='"+value.replace(/"/g, '\\x22').replace(/'/g, '\\x27')+"';\">"+value.slice(0,9)+"&hellip;</abbr>")+"</i>";
    }
  });
  return '<tr><td>' + (!message.source || message.source == "backend" ? "" : "[" + message.source.replaceAll("_", " ").toUpperCase() + "] ") +
    capitalize(message.activity.replaceAll("_", " ")) + " "+
    capitalize(message.event.replaceAll("_", " ")) +
    (data != "" ? " <span class=\"small text-muted\" style=\"overflow-wrap: break-word;\">(" + data + ")</span>" : "") +
    '</td><td>'+log_format_time(time)+'</td></tr>';
}
var log_tail_received = false;
export function log_tail(message) {
  if(log_tail_received) return;
  var now = message.clock || new Date().getTime(); //clock was added in newer version
  var html = "";
  for(var i = 0; i<message.logs.length; i++) {
    var log = message.logs[i];
    var time = new Date((log.time - message.time)/1000 + now);
    html += log_format_message(log, time);
  }
  $('#device_control_logs tbody').prepend(html);
  $("#device_control_log_tail_loading").remove();
  $("#device_control_log_device_waiting").remove();
  $("#hdScrolldevice_control_logs").scrollTop($("#device_control_logs").height());
}
export function log_live(message) {
  var clock = message.clock ? new Date(message.clock) : new Date(); //clock was added in newer version
  log_append(log_format_message(message.log, clock));
}
export function log_live_channel_event(message) {
  if(message.event == "connected") {
    log_append('<tr><td><i class="fa-regular fa-fw fa-hard-drive text-success" aria-hidden="true"></i> Device connected to live channel <span class="small text-muted">(session_id: <i>'+message.session_id+'</i>)</span></td><td>'+log_format_time(new Date())+'</td></tr>');
  }
  if(message.event == "subscribed") {
    log_ask_data();
    if(!log_tail_received) {
      log_ask_tail();
      if(!document.getElementById("device_control_log_tail_loading")) {
        log_append('\
          <tr id="device_control_log_tail_loading">\
            <td colspan="2"><i class="fa-solid fa-circle-notch fa-spin"></i> Loading small amount of history&hellip;</td>\
          </tr>'
        );
      }
    }
  }
  if(message.event == "disconnected") {
    log_append('<tr><td><i class="fa-regular fa-fw fa-hard-drive text-danger" aria-hidden="true"></i> Device disconnected from live channel <span class="small text-muted">(session_id: <i>'+message.session_id+'</i>)</span></td><td>'+log_format_time(new Date())+'</td></tr>');
  }
}
function log_ask_data() {
  if(device_control_connected) device_control_subscription.perform('log', {log_command: 'report'});
}
function log_ask_tail() {
  if(device_control_connected) device_control_subscription.perform('log', {log_command: 'tail', count: 100});
}
function log_ask_history() {
  if(device_control_connected) device_control_subscription.perform('log', {log_command: 'history'});
}
export function log_connected(was_disconnected) {
  if(was_disconnected) log_state({state: "reconnecting", message: "Some records may be missing"});
  log_state({state: "connected", message: "Connected to live stream"});

  if(!was_disconnected) { // first run
    log_ask_tail();
    if(log_device_starts_online) {
      log_append('\
        <tr id="device_control_log_tail_loading">\
          <td colspan="2"><i class="fa-solid fa-circle-notch fa-spin"></i> Loading small amount of history&hellip;</td>\
        </tr>'
      );
    } else {
      log_append('<tr id="device_control_log_device_waiting"><td colspan="2"><i class="fa-regular fa-fw fa-hard-drive text-warning" aria-hidden="true"></i> Device is not connected to live channel, waiting&hellip;</td></tr>');
    }
    log_ask_data();
    setInterval(log_ask_data, 5000);
  }
}
export function log_disconnected(reject) {
  if(reject) log_state({state: "disconnected", message: "Rejected connection to live stream"});
  else log_state({state: "disconnected", message: "Disconnected from live stream"});
}


export function log_history_init(facility_id, tz_offset) {
  log_facility_id = facility_id;
  log_tz_offset = tz_offset;
}
export function log_history_connected() {
  log_state({state: "connected", message: "Connected to live stream"});
  log_ask_history();
  log_append('\
    <tr>\
      <td colspan="2"><i class="fa-solid fa-circle-notch fa-spin"></i> Loading all available history&hellip;</td>\
    </tr>'
  );
}
export function log_history_disconnected(reject) {
  $('#device_control_logs tbody').html("");
  log_disconnected(reject);
}
export function log_history(message) {
  $('#device_control_logs tbody').html("");
  var now = message.clock || new Date().getTime(); //clock was added in newer version
  for(var i = 0; i<message.logs.length; i++) {
    var log = message.logs[i];
    var time = new Date((log.time - message.time)/1000 + now);
    $('#device_control_logs tbody').append(log_format_message(log, time));
  }
}
