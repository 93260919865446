export function hex2rgb(hex) {
  var m = hex.match(/^#([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
  if(m) return [parseInt(m[1], 16), parseInt(m[2], 16), parseInt(m[3], 16)];
  else return [0, 0, 0];
}
export function rgb2hex(rgb) {
  var hexR = rgb[0].toString(16);
  if(hexR.length < 2) hexR = "0" + hexR;
  var hexG = rgb[1].toString(16);
  if(hexG.length < 2) hexG = "0" + hexG;
  var hexB = rgb[2].toString(16);
  if(hexB.length < 2) hexB = "0" + hexB;
  return "#"+hexR+hexG+hexB;
}
export function rgb2rgbF(rgb) {
  return [rgb[0]/255.0, rgb[1]/255.0, rgb[2]/255.0];
}
export function rgbF2rgb(rgbF) {
  return [Math.round(rgbF[0]*255), Math.round(rgbF[1]*255), Math.round(rgbF[2]*255)];
}
export function rgbF2hsl(rgbF) {
  var cmax = Math.max(rgbF[0], rgbF[1], rgbF[2]);
  var cmin = Math.min(rgbF[0], rgbF[1], rgbF[2]);
  var d = cmax - cmin;
  var h = 0;
  if(d == 0);
  else if(cmax == rgbF[0]) h = 60 * (((rgbF[1]-rgbF[2])/d+6)%6);
  else if(cmax == rgbF[1]) h = 60 * ((rgbF[2]-rgbF[0])/d + 2);
  else if(cmax == rgbF[2]) h = 60 * ((rgbF[0]-rgbF[1])/d + 4);
  var l = (cmax+cmin)/2;
  var s = 0;
  if(d != 0) s = d/(1-Math.abs(2*l-1));
  return [h, s, l];
}
export function hsl2rgbF(hsl) {
  var c = (1 - Math.abs(2*hsl[2]-1))*hsl[1];
  var x = c * (1 - Math.abs((hsl[0]/60)%2 - 1));
  var m = hsl[2] - c/2;
  var rgbF = -1;
  if(0 <= hsl[0] && hsl[0] < 60) rgbF = [c, x, 0];
  else if(60 <= hsl[0] && hsl[0] < 120) rgbF = [x, c, 0];
  else if(120 <= hsl[0] && hsl[0] < 180) rgbF = [0, c, x];
  else if(180 <= hsl[0] && hsl[0] < 240) rgbF = [0, x, c];
  else if(240 <= hsl[0] && hsl[0] < 300) rgbF = [x, 0, c];
  else if(300 <= hsl[0] && hsl[0] < 360) rgbF = [c, 0, x];
  return [rgbF[0]+m, rgbF[1]+m, rgbF[2]+m];
}
//helpers
export function hex2hsl(hex) {
  return rgbF2hsl(rgb2rgbF(hex2rgb(hex)));
}
export function hsl2hex(hsl) {
  return rgb2hex(rgbF2rgb(hsl2rgbF(hsl)));
}
/*
export function test() {
  for(var r = 0; r<256; r++) {
    console.log("r = "+r);
    for(var g = 0; g<256; g++) {
      for(var b = 0; b<256; b++) {
        var hsl = rgbF2hsl(rgb2rgbF([r,g,b]));
        if(!(hsl[0] >= 0 && hsl[0] < 360)) {
          console.log("("+r+", "+g+", "+b+") = ("+hsl[0]+", "+hsl[1]+", "+hsl[2]+")");
          return;
        }
        var rgb = rgbF2rgb(hsl2rgbF(hsl));
        if(rgb[0] != r || rgb[1] != g || rgb[2] != b) {
          console.log("("+r+", "+g+", "+b+") != ("+rgb[0]+", "+rgb[1]+", "+rgb[2]+")");
          return;
        }
      }
    }
  }
}
test();
//*/
