import $ from "jquery"

(function($) {
  $.fn.closestOrParent = function(selector) {
    var closest = this.closest(selector);
    if(closest.length > 0) return closest;
    return this.parent();
  };
}($));

export function bindLogoBackgroundPreview(obj) {
  if(window.File && window.FileList && window.FileReader && window.FormData) {
    obj.find(".logo-background-colorpicker").colorpicker({
      format: 'hex',
      container: true,
      hexNumberSignPrefix: true,
      inline: true
    }).on('changeColor', function(e) {
      $(e.target).closestOrParent(".logo_background_preview").find(".img-thumbnail").css("background-color", e.color.toString());
    });
    obj.find(".logo-autopreview").bind("change", logoAutopreview);
  }
}
function lapd2h(d) {
  var s = Math.round(+d).toString(16);
  if(s.length < 2) s = '0' + s;
  return s;
}
function logoAutopreview(e) {
  if(e.target.files && e.target.files[0]) {
    var reader = new FileReader();
    reader.onload = function(readerEvent) {
      $(e.target).closestOrParent(".logo_background_preview").find(".logo_background_colorpicker").css("display", "");
      $(e.target).closestOrParent(".logo_background_preview").find(".img-thumbnail").css("display", "");
      $(e.target).closestOrParent(".logo_background_preview").find(".img-thumbnail img").attr("src", readerEvent.target.result);
      var image = new Image();
      image.onload = function(imageEvent) {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.getContext('2d').drawImage(image, 0, 0, image.width, image.height);
        var p = [
          canvas.getContext('2d').getImageData(0, 0, 1, 1).data,
          canvas.getContext('2d').getImageData(image.width-1, 0, 1, 1).data,
          canvas.getContext('2d').getImageData(0, image.height-1, 1, 1).data,
          canvas.getContext('2d').getImageData(image.width-1, image.height-1, 1, 1).data
        ].sort(function(a, b) {
          return a[0]+a[1]+a[2]+a[3] > b[0]+b[1]+b[2]+b[3];
        });
        var o = ((p[1][3]+p[2][3])/2)/255.0;
        var c = [(p[1][0]+p[2][0])/2.0*o+255*(1.0-o), (p[1][1]+p[2][1])/2.0*o+255*(1.0-o), (p[1][2]+p[2][2])/2.0*o+255*(1.0-o)];
        $(e.target).closestOrParent(".logo_background_preview").find('.logo-background-colorpicker').colorpicker("setValue", "#"+lapd2h(c[0])+lapd2h(c[1])+lapd2h(c[2]));
      }
      image.src = readerEvent.target.result;
    }
    reader.readAsDataURL(e.target.files[0]);
  }
}

$(function() {
  bindLogoBackgroundPreview($('.container-decorate'));
});

export function bindThumbnailBgAutoLoaded(obj) {
  obj.find(".thumbnail_bg_auto img").bind("load", thumbnailBgAutoLoaded).each(thumbnailBgAutoLoaded);
}
export function thumbnailBgAutoLoaded() {
  if(this.complete && this.naturalWidth > 0) {
    var canvas = document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;
    canvas.getContext('2d').drawImage(this, 0, 0, 64, 64);
    var pixels = canvas.getContext('2d').getImageData(0, 0, 64, 64).data; //rgba 0-255
    var pixels_sum = 0, opacity_sum = 0;
    for(var i = 0; i<64*64; i++) {
      pixels_sum += ((pixels[i*4] + pixels[i*4+1] + pixels[i*4+2])*pixels[i*4+3])/3;
      opacity_sum += pixels[i*4+3];
    }
    var shade = opacity_sum > 0 ? pixels_sum/opacity_sum : 0;
    //console.log(shade);
    if(shade > 128) $(this).closest(".thumbnail_bg_auto").addClass("thumbnail_bg_auto_dark");
    else $(this).closest(".thumbnail_bg_auto").removeClass("thumbnail_bg_auto_dark");
  }
}
$(function() {
  bindThumbnailBgAutoLoaded($('.container-decorate'));
});
